import React from "react";
import Image from "../Hacks/Image";
import { Link } from "gatsby";
import { Fade } from "react-awesome-reveal";
export const SuccessCard = ({ title, excerpt, image, slug }) => {
  return (
    <div className="success-card card h-100" data-aos="fade-up">
      <Fade>
        <div className="card-img-top">
          <Link cover={`true`} direction="right" bg="#1F1A3C" to={slug}>
            {image && <Image image={image} className="w-100" alt="" />}
          </Link>
        </div>
        <div className="card-body">
          <h2 className="card-title h4" data-aos="fade-in">
            {title}
          </h2>
          <div className="card-text">{excerpt}</div>
          <Link
            cover={`true`}
            direction="right"
            bg="#1F1A3C"
            className="btn btn-link btn-sm px-0 stretched-link"
            to={slug}
          >
            <span className="sr-only">
              Read More about how {title} succeed with Databowl
            </span>
          </Link>
        </div>
      </Fade>
    </div>
  );
};

export default SuccessCard;
